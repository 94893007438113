import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import IconZoom from '@/assets/img/ico_contents_zoom@2x.png';
import FileContainer from '@/components/AttachmentBox';
import BoardPrevNext, { PrevNextArticle } from '@/components/BoardPrevNext';
import Button from '@/components/Button';
import NewsThumbCard from '@/components/Card/NewsThumbCard';
import CKEditorContent from '@/components/CKEditorContent';
import Container from '@/components/Container';
import Section from '@/components/Section';
import { H2, Tit } from '@/components/Titles';
import { BOARD_KIND_VILLAGE } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const ArticleHeader = styled.div`
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e5e6e8;

  ${Tit} {
    letter-spacing: -0.8px;
    line-height: 1.56;

    ${breakpoint(`mobile`)} {
      font-size: 18px;
    }
  }

  ${breakpoint(`mobile`)} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const ArticleBody = styled.div`
  padding: 0 0 64px 0;
  border-bottom: 2px solid #e5e6e8;
`;
const ArticleContent = styled(CKEditorContent)`
  padding: 0 0 32px 0;
  line-height: 2;
  letter-spacing: -0.8px;
  word-break: break-all;

  table {
    th,
    td {
      text-align: initial;
    }
  }

  ${breakpoint(`mobile`)} {
    img {
      max-width: 100%;
      height: auto !important;
    }

    table {
      th,
      td {
        word-break: break-all;
      }
    }
  }
`;

const Note = styled.div`
  line-height: 1.71;
  letter-spacing: -0.8px;

  ${breakpoint(`mobile`)} {
    align-self: flex-end;
    margin-top: 8px;
  }
`;
const ArticleContentZoom = styled.div`
  text-align: right;
  padding: 32px 0;

  button {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-image: url(${IconZoom});
    background-size: 79px 40px;
    background-repeat: no-repeat;
  }
`;
const ZoomUp = styled.button`
  background-position: right top;
  margin-left: -1px;
`;
const ZoomDown = styled.button`
  background-position: left top;
`;

const RelatedContainer = styled.dl`
  padding-top: 64px;

  ${breakpoint(`tablet`)} {
    padding-top: 48px;
    margin-top: 24px;
  }

  dd {
    padding-top: 32px;

    .swiper-container {
      margin-left: -16px;
      margin-right: -16px;
    }

    .swiper-slide {
      width: 25%;
      padding: 0 16px;

      ${breakpoint(`tablet`)} {
        width: 50%;
      }
    }

    .img-box {
      padding-top: 100%;
    }

    p {
      padding-left: 0;
      padding-right: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const BtnWrap = styled.div`
  text-align: center;
  padding: 64px 0;

  ${breakpoint(`tablet`)} {
    padding: 48px 0;
  }
`;

const VillageDetail: FC<PageProps> = ({
  location,
  params: { boardIndexNumber },
}) => {
  const params = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );

  const [fontSize, setFontSize] = useState(16);
  const [prevItem, setPrevItem] = useState<PrevNextArticle | undefined>();
  const [nextItem, setNextItem] = useState<PrevNextArticle | undefined>();
  const [article, setArticle] = useState<BoardVo>();

  const fontSizeUp = () => setFontSize(fontSize + 1);
  const fontSizeDown = () => setFontSize(fontSize - 1);

  // 어린이 지구촌 체험관 상세 로드
  const loadDetail = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foDetailUsingGet({
        boardCategoryCode: BOARD_KIND_VILLAGE,
        boardIndexNumber,
      });
      const village = data as any;
      setArticle(village);
      setPrevItem({
        boardIndexNumber: village.prevBoardIndexNumber,
        subject: village.prevSubject,
        firstRegisterDate: village.prevRegDate,
      });
      setNextItem({
        boardIndexNumber: village.nextBoardIndexNumber,
        subject: village.nextSubject,
        firstRegisterDate: village.nextRegDate,
      });
    } catch (e) {
      console.error(e);
    }
  }, [boardIndexNumber]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  // 이전글 다음글 동적 로딩
  const loadPage = useCallback(
    async (targetKeyword: string) => {
      const {
        resultCode,
        data: _data,
      } = await BoardCommonControllerService.foDetailUsingGet({
        boardCategoryCode: BOARD_KIND_VILLAGE,
        boardIndexNumber,
        keyword: targetKeyword,
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        // 이전글 다음글 세팅
        setPrevItem({
          boardIndexNumber: newData.prevBoardIndexNumber,
          subject: newData.prevSubject,
          firstRegisterDate: newData.prevRegDate,
        });
        setNextItem({
          boardIndexNumber: newData.nextBoardIndexNumber,
          subject: newData.nextSubject,
          firstRegisterDate: newData.nextRegDate,
        });
      }
    },
    [boardIndexNumber],
  );

  const popupStore = usePopupStore();
  const updateViewCnt = useCallback(async () => {
    const {
      resultCode,
      resultMessage,
    } = await BoardCommonControllerService.viewCntUsingPost({
      boardIndexNumber,
    });

    if (resultCode !== `success`) {
      if (resultMessage !== undefined) {
        popupStore.show(resultMessage);
      } else {
        console.error(`${boardIndexNumber} 조회수 증가를 실패했습니다.`);
      }
    }
  }, []);

  // keyword 변경되면 업데이트
  useEffect(() => {
    if (params.keyword) {
      loadPage(params.keyword);
    }
    updateViewCnt();
  }, [boardIndexNumber, loadPage, params.keyword, updateViewCnt]);

  if (!article) {
    return null;
  }

  return (
    <LayoutWithTitle
      location={location}
      title="어린이지구촌체험관"
      description="for every child, experience"
    >
      <Section>
        <Container
          css={`
            padding-top: 96px;

            ${breakpoint(640)} {
              padding-top: 48px;
            }
          `}
        >
          <article>
            <ArticleHeader>
              <Tit size="s3-1">{article.subject}</Tit>
              <Note>
                {dayjs(article.dispStartDate, `YYYY.MM.DD`).format(
                  `YYYY.MM.DD`,
                )}
              </Note>
            </ArticleHeader>
            <ArticleContentZoom>
              <ZoomDown type="button" onClick={fontSizeDown}>
                <span className="for-a11y">축소</span>
              </ZoomDown>
              <ZoomUp type="button" onClick={fontSizeUp}>
                <span className="for-a11y">확대</span>
              </ZoomUp>
            </ArticleContentZoom>
            <ArticleBody>
              <ArticleContent
                style={{ fontSize }}
                dangerouslySetInnerHTML={{
                  __html:
                    article.contents &&
                    article.contents.replace(/href/g, `target='_blank' href`),
                }}
              />
              {article.arrAttFile !== undefined &&
                article.arrAttFile.length > 0 && (
                  <FileContainer files={article.arrAttFile} />
                )}
            </ArticleBody>
            {article.arrRelBoard?.length
              ? article.arrRelBoard !== undefined && (
                  <RelatedContainer>
                    <dt>
                      <H2>관련 글</H2>
                    </dt>
                    <dd>
                      <NewsThumbCard
                        boards={article.arrRelBoard}
                        baseUri="/involve/child-participation/village"
                        queryString={location.search}
                      />
                    </dd>
                  </RelatedContainer>
                )
              : null}

            <BtnWrap>
              <Button
                color="grey"
                size="sm"
                width={256}
                onClick={() =>
                  navigate(
                    `/involve/child-participation/village${location.search}`,
                  )
                }
              >
                목록
              </Button>
            </BtnWrap>
            <BoardPrevNext
              prev={prevItem}
              next={nextItem}
              baseUri="/involve/child-participation/village"
              queryString={location.search}
            />
            {/* TODO 해당 테그 확인후 삭제 필요
              <ArticleFoot /> */}
          </article>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default VillageDetail;
